import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";

import { Header, SectionJobs } from "components";
import { Layout } from "containers";
import {
  getPageCategory,
  getPageConfig,
  getPageFilters,
  getPageMascot,
} from "utils/page";

const TemplateJobCategory = ({
  data: { config, imageMascots, jobCategories, jobs },
  location,
}) => {
  const jobCategory = getPageCategory(location.pathname);
  const imageMascot = getPageMascot(imageMascots, jobCategory);
  const { header, layout, seo } = getPageConfig(config, jobCategory);

  const layoutProps = {
    seoProps: seo,
    ...layout,
  };

  const headerProps = {
    captionProps: {
      className: "space-right-image",
    },
    ...header,
  };

  const sectionJobsProps = {
    filters: getPageFilters(jobCategories, "/jobs"),
    jobs,
    location,
    mascot: {
      className: imageMascot.name,
      ...imageMascot,
    },
  };

  return (
    <Layout {...layoutProps}>
      <Header {...headerProps} />
      <SectionJobs {...sectionJobsProps} />
    </Layout>
  );
};

TemplateJobCategory.propTypes = {
  data: PropTypes.shape({
    config: PropTypes.shape({
      header: PropTypes.shape({
        heading: PropTypes.string,
      }),
      id: PropTypes.string,
      seo: PropTypes.shape({
        description: PropTypes.string,
        title: PropTypes.string,
      }),
    }),
    imageMascots: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          childImageSharp: PropTypes.shape({
            fluid: PropTypes.shape({
              base64: PropTypes.string,
              aspectRatio: PropTypes.number,
              src: PropTypes.string,
              srcSet: PropTypes.string,
              sizes: PropTypes.string,
            }),
          }),
          name: PropTypes.string,
        })
      ),
    }),
    jobCategories: PropTypes.shape({
      group: PropTypes.arrayOf(
        PropTypes.shape({
          category: PropTypes.oneOf([
            "Art",
            "Audio",
            "Design",
            "Development",
            "Social",
            "Video",
            "Writing",
          ]),
        })
      ),
    }),
    jobs: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            data: PropTypes.shape({
              companyLogo: PropTypes.oneOfType([
                PropTypes.shape({
                  localFiles: PropTypes.arrayOf(
                    PropTypes.shape({
                      childImageSharp: PropTypes.shape({
                        fluid: PropTypes.shape({
                          base64: PropTypes.string,
                          aspectRatio: PropTypes.number,
                          src: PropTypes.string,
                          srcSet: PropTypes.string,
                          sizes: PropTypes.string,
                        }),
                      }),
                      name: PropTypes.string,
                    })
                  ),
                }),
                PropTypes.string,
              ]),
              companyName: PropTypes.string,
              date: PropTypes.string,
              id: PropTypes.string,
              jobCategory: PropTypes.oneOf([
                "Art",
                "Audio",
                "Design",
                "Development",
                "Social",
                "Video",
                "Writing",
              ]),
              jobTitle: PropTypes.string,
            }),
            id: PropTypes.string,
          }),
        })
      ),
    }),
  }),
};

export const jobsByCategoryQuery = graphql`
  query JobsByCategory($jobCategory: String!) {
    config: allPagesYaml {
      edges {
        node {
          header {
            heading
          }
          id
          seo {
            description
            title
          }
        }
      }
    }
    imageMascots: allFile(filter: { absolutePath: { regex: "/mascot/" } }) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 600, quality: 90, traceSVG: { color: "#ced0d4" }) {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
          name
        }
      }
    }
    jobCategories: allAirtable(
      filter: { data: { isPublished: { eq: 1 } }, table: { eq: "Jobs" } }
    ) {
      group(field: data___jobCategory) {
        category: fieldValue
      }
    }
    jobs: allAirtable(
      filter: {
        data: { isPublished: { eq: 1 }, jobCategory: { eq: $jobCategory } }
        table: { eq: "Jobs" }
      }
      sort: { fields: data___date, order: DESC }
    ) {
      edges {
        node {
          data {
            # NOTE: use this query if companyLogo is an attachment type
            companyLogo {
              localFiles {
                childImageSharp {
                  fluid(maxWidth: 480, quality: 90) {
                    ...GatsbyImageSharpFluid_tracedSVG
                  }
                }
              }
            }
            companyName
            companyLogoUrl
            date(formatString: "MMMM DD, YYYY")
            id
            jobCategory
            jobTitle
          }
          id
        }
      }
    }
  }
`;

export default TemplateJobCategory;
